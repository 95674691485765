import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useGetShowcaseQuery } from "../../app/services/mastraApi";
import { useHistory } from "react-router-dom";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { bookPath } from "../urlGenerator";

function BookCarousel(props) {
  const { data: books, isLoading } = useGetShowcaseQuery();

  return (
    <>
      <Container>
        <Box fontSize={23} textAlign="center" style={{ paddingTop: "35px" }}>
          {props.title}
        </Box>
      </Container>
      <div
        style={{
          width: "100%",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        {isLoading ? "Loading" : RenderCarousel(books)}
      </div>
    </>
  );
}

function RenderCarousel(books) {
  const responsive = {
    xs: {
      breakpoint: { min: 0, max: 576 },
      items: 1,
    },
    sm: {
      breakpoint: { min: 576, max: 768 },
      items: 2,
    },
    md: {
      breakpoint: { min: 768, max: 992 },
      items: 3,
    },
    lg: {
      breakpoint: { min: 992, max: 1200 },
      items: 4,
      slidesToSlide: 2,
    },
    xl: {
      breakpoint: { min: 1200, max: 1600 },
      items: 5,
      slidesToSlide: 2,
    },
    xxl: {
      breakpoint: { min: 1600, max: 9999 },
      items: 7,
      slidesToSlide: 2,
    },
  };

  if (books === undefined || books.length === 0) {
    return "";
  }

  return (
    <Carousel
      responsive={responsive}
      arrows
      infinite
      centerMode
      draggable={false}
      removeArrowOnDeviceType={["xs"]}
    >
      {books.map((book, idx) => (
        <CarouselItem key={idx} book={book} />
      ))}
    </Carousel>
  );
}

export function CarouselItem({ book }) {
  const history = useHistory();

  const href = bookPath(book.id, book.title);

  const handleClickedItem = (event) => {
    event.preventDefault();
    history.push(href);
  };

  return (
    <ImageListItem
      onClick={handleClickedItem}
      component="a"
      href={href}
      sx={{ mx: 1 }}
    >
      <img
        src={
          book.cover_lg ||
          "https://api.mastrabooks.com/static/data/cover/5ddeb887918b51328880911b/lg.jpeg"
        }
        alt={book.title}
        loading="lazy"
        style={{
          height: "240px",
          // filter: "grayscale(50%) blur(3px)",
          // opacity: 0.8,
        }}
      />
      <ImageListItemBar title={book.title} subtitle={book.authors.join(", ")} />
    </ImageListItem>
  );
}

export default BookCarousel;
