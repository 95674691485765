import { mastraApi } from "../../app/services/mastraApi";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const updateTokenReducer = (state, action) => {
  // Login success, set user into state and store token to localStorage
  localStorage.setItem("token", action.payload.key);
  return state;
};

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addMatcher(mastraApi.endpoints.login.matchFulfilled, updateTokenReducer)
      .addMatcher(
        mastraApi.endpoints.registration.matchFulfilled,
        updateTokenReducer
      );
  },
});

export default slice.reducer;
