import React from "react";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";

const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

/**
 * Email field with MaterialUI and react-hook-form validation already implemented
 * params:
 * control: control function coming from useForm() hook
 * errors: optional errors coming from useForm() hook. Default error is shown if email pattern is invalid
 * rules: optional, passed to Controller component for additional rules
 * other props are passed onto TextField input component
 */
export const ValidatedEmailField = ({
  control,
  errors,
  rules,
  ...otherInputProps
}) => (
  <Controller
    name="email"
    control={control}
    rules={{
      required: true,
      pattern: EMAIL_PATTERN,
      ...rules,
    }}
    render={({ field }) => (
      <TextField
        margin="normal"
        required
        fullWidth
        label="Email"
        name="email"
        type="email"
        autoComplete="email"
        error={errors.email}
        helperText={
          errors.email && (errors.email?.message || "Inserisci una mail valida")
        }
        {...field}
        {...otherInputProps}
      />
    )}
  />
);
