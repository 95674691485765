import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { selectListingInfo, resetFields } from "./listingsWizardSlice";
import { useSelector, useDispatch } from "react-redux";
import { useCreateListingMutation } from "../../app/services/mastraApi";
import { showError } from "../snackbar/snackbarSlice";

export default function StepCreateListing({
  bookId,
  isActive,
  nextStep,
  firstStep,
  onListingCreated,
}) {
  const dispatch = useDispatch();
  const lstInfo = useSelector(selectListingInfo);
  const [createListing, { data: listing, isSuccess, isError }] =
    useCreateListingMutation();

  // At step activation, wait a few secs then create listing
  useEffect(() => {
    if (!isActive) return;

    setTimeout(() => {
      createListing({ book: bookId, ...lstInfo });
    }, 2000);
  }, [isActive, createListing, lstInfo, bookId]);

  // Handle listing creation response
  useEffect(() => {
    if (!isActive) return;

    if (isSuccess) {
      dispatch(resetFields());
      if (onListingCreated) {
        onListingCreated(listing);
      }
      nextStep();
    } else if (isError) {
      dispatch(showError("Errore creazione annuncio. Per favore riprova."));
      firstStep();
    }
  }, [isSuccess, isError, isActive, dispatch, firstStep, nextStep]);

  return (
    <Box sx={{ width: "100%", textAlign: "center", my: 10 }}>
      <Typography sx={{ mb: 5 }}>Stiamo creando il tuo annuncio...</Typography>
      <LinearProgress />
    </Box>
  );
}
