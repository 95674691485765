import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import useHotjar from "react-use-hotjar";

const usePageTracking = () => {
  let location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const { initHotjar } = useHotjar();

  useEffect(() => {
    /** Executed only once to init Google Analytics */
    if (
      process.env.REACT_APP_GA_ENABLE === "true" &&
      process.env.REACT_APP_GA_MEASUREMENT_ID
    ) {
      ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
      setInitialized(true);
    }
  }, []);

  /** Init Hotjar website analytics and heatmapping tool */
  useEffect(() => {
    if (
      process.env.REACT_APP_HOTJAR_ENABLE === "true" &&
      process.env.REACT_APP_HOTJAR_SITE_ID
    ) {
      //TODO: disable logging in prod
      initHotjar(process.env.REACT_APP_HOTJAR_SITE_ID, 6, true, console.log);
    }
  }, [initHotjar]);

  useEffect(() => {
    /** Tracks every location change */
    if (initialized) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [initialized, location]);
};

export default usePageTracking;
