import React from "react";
import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useLazyGetBooksAutocompleteQuery } from "../../app/services/mastraApi";
import AutocompleteRow from "./AutocompleteRow";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";
import routes from "../routes";
import { reverse } from "named-urls";

export default function SearchBar({
  defaultValue = "",
  showSuggestions = true,
  onSearch,
  props,
}) {
  const [queryTerm, setQueryTerm] = useState(defaultValue);
  const [triggerQuery, { data }] = useLazyGetBooksAutocompleteQuery();
  const history = useHistory();

  const inputField = ({
    InputProps: { ref },
    InputLabelProps: _InputLabelProps,
    ...other
  }) => (
    <Paper
      ref={ref}
      component="form"
      sx={{ p: "4px 2px 4px 20px", display: "flex" }}
      elevation={2}
    >
      <InputBase
        name="query"
        type="text"
        placeholder="Cerca un libro"
        sx={{ flex: 1 }}
        {...other}
      />
      <IconButton sx={{ p: "10px" }} aria-label="search" type="submit">
        <SearchIcon />
      </IconButton>
    </Paper>
  );

  /** On each user keystroke, query backend */
  const handleKeystroke = (e, newInputValue) => {
    setQueryTerm(newInputValue);
    triggerQuery({ q: newInputValue });
  };

  /** When user clicks a search suggestion */
  const handleSuggestionClick = (e, option, reason) => {
    if (reason !== "selectOption") return;

    if ("text" in option) {
      // User selected the first suggestion
      // which is the free search option
      handleFreeSearch(option.text);
      return;
    }
    if (onSearch && option) {
      // User selected a specific book from suggestions
      onSearch(option);
    }
  };

  const handleFreeSearch = (queryTerm) => {
    /** User submitted free search without selecting a specific book */
    history.push(reverse(routes.search, { query: queryTerm }));
  };

  const getOptionLabel = (option) => {
    return typeof option === "string"
      ? option
      : "text" in option
      ? option.text // First result
      : `${option.title} (${option.id})`; // value is used as key={}, so use title+id in case of 2 books with same title
  };

  const addQueryAsFirstSuggestion = (options) => {
    // Since options are filtered from backend, we do not
    // need to filter them. Instead, we append current query
    // so the user query is always the first result
    // NOTE: put queryTerm inside object to obtain same UI as other suggestions
    let userQuery = queryTerm ? [{ text: queryTerm }] : [];
    return [...userQuery, ...options];
  };

  if (showSuggestions) {
    // Display search bar with autocompletitions as user types
    return (
      <Autocomplete
        filterOptions={addQueryAsFirstSuggestion}
        renderInput={inputField} // Input search bar
        renderOption={AutocompleteRow}
        options={data || []}
        getOptionLabel={getOptionLabel}
        onInputChange={handleKeystroke}
        onChange={handleSuggestionClick}
        value={queryTerm}
        freeSolo
        autoHighlight
        onSubmit={(event) => {
          event.preventDefault();
          handleFreeSearch(event.target.query.value);
        }}
        {...props}
      />
    );
  } else {
    return (
      // Search bar without suggestions is obtained by setting options={}
      // We do not monitor user typing, only callback is on submit
      <Autocomplete
        renderInput={inputField} // Input search bar
        options={[]}
        defaultValue={queryTerm || ""}
        freeSolo
        onSubmit={(event) => {
          event.preventDefault();
          handleFreeSearch(event.target.query.value);
        }}
        {...props}
      />
    );
  }
}
