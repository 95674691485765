import * as React from "react";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";
import { ListItemIcon } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export default function AutocompleteRow(props, option) {
  if ("title" in option) {
    return <BookResult {...props} book={option} />;
  } else {
    return <StringResult {...props} text={option.text} />;
  }
}

function StringResult({ text, ...other }) {
  return (
    <ListItemButton {...other}>
      <ListItemIcon>
        <SearchIcon sx={{ ml: 1 }} />
      </ListItemIcon>
      <ListItemText primary={text} secondary="Cerca su Mastrabooks" />
    </ListItemButton>
  );
}

function BookResult({ book, ...other }) {
  return (
    <ListItemButton {...other}>
      <ListItemAvatar>
        <Avatar
          alt={`${book.title} cover`}
          src={book.cover_lg || ""}
          variant="square"
        />
      </ListItemAvatar>
      <ListItemText
        primary={book.title}
        secondary={book.authors?.join(", ") || ""}
      />
    </ListItemButton>
  );
}
