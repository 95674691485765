import React from "react";
import { useHistory } from "react-router-dom";
import SearchBar from "../search/Searchbar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import BookCarousel from "../carousel/BookCarousel";
import { bookPath } from "../urlGenerator";
import Grid from "@mui/material/Grid";
import homeimg_1 from "../../assets/home_1.png";
import homeimg_2 from "../../assets/home_2.png";
import homeimg_3 from "../../assets/home_3.png";
export default function Home() {
  const history = useHistory();

  const handleSearch = (bookSuggestion) => {
    history.push(bookPath(bookSuggestion.id, bookSuggestion.title));
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          paddingTop: 10,
          textAlign: "center",
        }}
      >
        <Container fixed>
          <Typography variant="h3" component="h1">
            Compra e vendi libri in università con due click.
          </Typography>
          <Typography variant="h5" component="h2">
            Per iniziare inserisci un titolo o ISBN, al resto pensiamo noi.
          </Typography>
          <Box sx={{ py: 10 }}>
            <SearchBar onSearch={handleSearch} />
          </Box>
        </Container>
      </Box>
      <BookCarousel title="Libri universitari più venduti" />
      {/**<BookCarousel title="Trending in Personal finance" />*/}

      <br></br>
      <br></br>

      <Grid container spacing={2} backgroundColor="#dadada" padding={5}>
        <Grid
          alignItems="center"
          item
          xs={12}
          md={4}
          align="center"
          justify="center"
        >
          <Container>
            <h2>Cerca un libro</h2>
            <img src={homeimg_1} alt="cerca libro" height="220"></img>
            <Typography variant="body1" padding={3} color="#424242">
              Trova un venditore o diventalo tu stesso! Inizia inserendo nella
              barra di ricerca un titolo, autore, o ISBN.
            </Typography>
          </Container>
        </Grid>
        <Grid
          alignItems="center"
          item
          xs={12}
          md={4}
          align="center"
          justify="center"
        >
          <Container>
            <h2>Accordati con un altro studente</h2>
            <img src={homeimg_2} alt="cerca libro" height="220"></img>
            <Typography variant="body1" padding={3} color="#424242">
              Seleziona la tua università, clicca l&apos;annuncio piu adatto ed
              inizia subito a chattare via Email.
            </Typography>
          </Container>
        </Grid>
        <Grid
          alignItems="center"
          item
          xs={12}
          md={4}
          align="center"
          justify="center"
        >
          <Container>
            <h2>Concludi l&apos;affare</h2>

            <img src={homeimg_3} alt="affare" height="220"></img>
            <Typography variant="body1" padding={3} color="#424242">
              Paga in contanti al momento dello scambio.
            </Typography>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
