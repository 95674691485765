import { ValidatedEmailField } from "./ValidatedEmailField";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useEffect } from "react";
import { useRegistrationMutation } from "../../app/services/mastraApi";
import { useDispatch } from "react-redux";
import { showSuccess } from "../snackbar/snackbarSlice";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { Copyright } from "./Copyright";

export function Register() {
  const dispatch = useDispatch();
  const [register, { error, isSuccess }] = useRegistrationMutation();

  // Form validation hook
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password1: "",
    },
  });

  // Handle form submission
  const onSubmit = (data) => {
    register({ ...data });
  };

  // On successful register, show a toast message
  useEffect(() => {
    if (isSuccess) {
      dispatch(showSuccess("Account registrato, benvenuto su Mastrabooks!"));
    }
  }, [isSuccess, dispatch]);

  useEffect(() => {
    if (error) {
      for (const key in error.data) {
        setError(key, { type: "server", message: error.data[key] });
      }
    }
  }, [error, setError]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrati
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <ValidatedEmailField control={control} errors={errors} />

          <Controller
            name="password1"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                name="password1"
                label="Password"
                type="password"
                autoComplete="new-password"
                error={errors.password1}
                helperText={errors.password1?.message}
                {...field}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Registrati
          </Button>

          <Button fullWidth component={Link} to="/login">
            {"Sei già registrato? Entra"}
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
