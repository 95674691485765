import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectListingInfo, setListingInfo } from "./listingsWizardSlice";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useAuth } from "../auth/useAuth";
import { AVAIL_CONDITIONS } from "../marketplace/Listings";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useForm, Controller, useWatch } from "react-hook-form";
import UniSelectAutocomplete from "./UniSelectAutocomplete";

function StepAddListingInfo({ nextStep, goToStep }) {
  const { isLoggedIn } = useAuth();

  const dispatch = useDispatch();
  const listingInfo = useSelector(selectListingInfo);

  // Form validation hook
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: listingInfo });

  const onSubmit = (data) => {
    // Update redux store
    dispatch(setListingInfo(data));
    // Skip contacts if user is logged in
    if (isLoggedIn) {
      goToStep(3);
    } else {
      nextStep();
    }
  };

  // Watch notes field to update char count
  const notes = useWatch({
    control,
    name: "notes",
  });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography
        variant="h4"
        component="h1"
        noWrap
        align="center"
        sx={{ mb: 4 }}
      >
        Inserimento annuncio
      </Typography>

      {/* Book condition field */}
      <Controller
        name="condition"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl required fullWidth>
            <InputLabel id="condition-label">Stato</InputLabel>
            <Select
              label="Stato ."
              labelId="condition-label"
              error={errors.condition}
              {...field}
            >
              {AVAIL_CONDITIONS.map((condition, i) => (
                <MenuItem key={i} value={condition.id}>
                  {condition.desc}
                </MenuItem>
              ))}
            </Select>
            {errors.condition && (
              <FormHelperText error>Seleziona uno stato</FormHelperText>
            )}
          </FormControl>
        )}
      />

      <p></p>

      {/* University selector field */}
      {/* <Controller
        name="university"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          // <UniAutocompletedSelect
          //   errors={errors}
          //   formControlProps={{ required: true }}
          //   fieldProps={field}
          // />
          )}
      /> */}
      <UniSelectAutocomplete control={control} required />

      {/* Price field */}
      <Controller
        name="price"
        control={control}
        rules={{ required: true, min: 0, pattern: /^\d+((\.)\d{1,2})?$/ }}
        render={({ field }) => (
          <TextField
            label="Prezzo"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
            fullWidth
            sx={{ mt: 2 }}
            required
            error={errors.price}
            helperText={errors.price && "Inserisci un prezzo valido"}
            {...field}
          />
        )}
      />

      {/* Notes field */}
      <Controller
        name="notes"
        control={control}
        rules={{ maxLength: 64 }}
        render={({ field }) => (
          <FormControl fullWidth sx={{ mt: 2 }}>
            <TextField
              label="Note"
              multiline
              rows={4}
              error={errors.notes}
              helperText={`Caratteri: ${notes.length}/64`}
              {...field}
              onChange={(e) => {
                if (e.target.value.length <= 64) {
                  field.onChange(e);
                }
              }}
            />
          </FormControl>
        )}
      />

      <Box sx={{ textAlign: "right" }}>
        <Button
          variant="contained"
          type="submit"
          endIcon={<ArrowForwardIcon />}
          sx={{ mt: 2 }}
        >
          {isLoggedIn ? "Crea annuncio" : "Avanti"}
        </Button>
      </Box>
    </Box>
  );
}

export default StepAddListingInfo;
