import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./useAuth";
import { useUrlQuery } from "../useUrlQuery";

/**
 * Provide a react-dom-router route which handles conditional rendering
 * renders privateComponent if user is logged in
 * renders publicComponent if user is NOT logged in
 *
 * By default, logged in user will be redirected to profile page if no privateComponent is specified
 * By default, NOT logged in users will be redirected to login page if no publicComponent is specified
 * NOTE: redirect destination can be overridden at runtime by adding ?redirect=/my/page to the URL
 *
 * Example usage #1: Render Login component for anon users, redirect logged users to /profile
 * <AuthRoute exact path="/login" publicComponent={<Login />} />
 *
 * Example usage #2: Render Profile component for logged in users, redirect anons to /login
 * <AuthRoute exact path="/profile" privateComponent={<ProfilePage />} />
 *
 * Example usage #3: redirect to a specific page after login
 * Set browser URL to /login?redirect=/my/page
 * <AuthRoute exact path="/login" publicComponent={<Login />} />
 */
export function AuthRoute({ privateComponent, publicComponent, ...other }) {
  const { isLoggedIn } = useAuth();
  console.log(`[AuthRoute] isLoggedIn=${isLoggedIn}`);

  // Prepare redirects
  const redirectUrl = useUrlQuery().get("redirect");

  // If no private component is specified, take user to its profile page
  // and also replace the public page from history so if the user presses
  // back button he is not stuck
  privateComponent = privateComponent || (
    <Redirect to={redirectUrl || "/profile"} />
  );

  // If no public component is present, redirect to login by default
  // removing the private page from history
  publicComponent = publicComponent || (
    <Redirect to={redirectUrl || "/login"} />
  );

  return (
    <Route
      {...other}
      render={({ location: _location }) =>
        isLoggedIn ? privateComponent : publicComponent
      }
    />
  );
}
