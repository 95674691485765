import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listingInfo: {
    condition: "",
    price: "",
    notes: "",
    university: "",
  },
  email: "",
};

const slice = createSlice({
  name: "listingsWizard",
  initialState: initialState,
  reducers: {
    setListingInfo: (state, action) => {
      state.listingInfo = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    resetFields: () => initialState,
  },
});

export const { setListingInfo, setEmail, resetFields } = slice.actions;

export const selectListingInfo = (state) => state.listingsWizard.listingInfo;
export const selectEmail = (state) => state.listingsWizard.email;

export default slice.reducer;
