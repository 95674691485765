import * as React from "react";
import { useGetBookByIdQuery } from "../../app/services/mastraApi";
import Button from "@mui/material/Button";

export default function BookTile({ bookId }) {
  const { data: b, error, isLoading } = useGetBookByIdQuery(bookId);

  const buttons = () => (
    <>
      <Button
        variant="contained"
        color="primary"
        href={b.amz_ref}
        target="_blank"
        rel="noreferrer"
        fullWidth
      >
        Acquista su Amazon
      </Button>
    </>
  );

  return (
    <>
      {error ? (
        <>Oh no, there was an error</>
      ) : isLoading ? (
        <>Loading...</>
      ) : b ? (
        <>{buttons()}</>
      ) : null}
    </>
  );
}
