import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetBookByIdQuery } from "../../app/services/mastraApi";
import { useHistory } from "react-router-dom";
import Listings from "../marketplace/Listings";
import CardNewBook from "../marketplace/CardNewBook";
import Container from "@mui/material/Container";
import BookTile from "../library/BookTile";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useState } from "react";
import UniSelectAutocomplete from "../ListingsWizard/UniSelectAutocomplete";
export default function BookPage() {
  const { bookId } = useParams();
  const { isError, error } = useGetBookByIdQuery(bookId);
  const history = useHistory();

  const [uniId, setUniId] = useState("");

  useEffect(() => {
    if (isError && error.status === 404) {
      history.replace("/404");
    }
  }, [isError, error, history]);

  return (
    <Container fixed sx={{ mt: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} lg={4}>
          <BookTile bookId={bookId} />
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          {/* University selector field */}
          <Typography variant="h6" sx={{ my: 1, color: "primary.main" }}>
            Seleziona università
          </Typography>
          <UniSelectAutocomplete
            onChange={setUniId}
            label="Filtra per università"
          />
          {/**End university selector field */}

          <Typography variant="h6" sx={{ my: 1, color: "primary.main" }}>
            Disponibilità usato
          </Typography>
          <Listings bookId={bookId} uniId={uniId} />
          <Typography variant="h6" sx={{ mb: 1, color: "primary.main" }}>
            Disponibilità nuovo
          </Typography>
          <CardNewBook bookId={bookId}></CardNewBook>
        </Grid>
      </Grid>
    </Container>
  );
}
