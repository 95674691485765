import React from "react";
import { Link } from "react-router-dom";

export default function UnstyledLink({ children, ...otherProps }) {
  return (
    <Link style={{ textDecoration: "none", color: "inherit" }} {...otherProps}>
      {children}
    </Link>
  );
}
