import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import snackbarReducer from "../features/snackbar/snackbarSlice";
import listingsWizardReducer from "../features/ListingsWizard/listingsWizardSlice";
import { mastraApi } from "./services/mastraApi";
import authReducer from "../features/auth/authSlice";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  reducer: {
    auth: authReducer,
    snackbar: snackbarReducer,
    listingsWizard: listingsWizardReducer,
    [mastraApi.reducerPath]: mastraApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(mastraApi.middleware),
  enhancers: [sentryReduxEnhancer],
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
