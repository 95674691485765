import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import UnstyledLink from "../UnstyledLink";

function AppBarCore(props) {
  return (
    <AppBar {...props}>
      <Container fixed>
        <Toolbar>
          <Typography
            variant="h6"
            component={UnstyledLink}
            to="/"
            sx={{ flexGrow: 1 }}
          >
            MastraBooks
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default function FixedAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBarCore />
      {/* Simple hack to obtain a fixed but keeping
      the right margin before page start */}
      <AppBarCore position="static" />
    </Box>
  );
}
