import { useMemo } from "react";
import { useGetProfileQuery } from "../../app/services/mastraApi";

/**
  This hook provides:
  user:         contains user data if user is logged in, falsy if not logged in
  isLoggedIn:   boolean

  Values are not updated if the request is pending, but only on
  success (user authenticated) or failure (bad login or user disconnected)
*/
export const useAuth = () => {
  const { data, isSuccess } = useGetProfileQuery();

  return useMemo(() => {
    return {
      user: isSuccess && data,
      isLoggedIn: Boolean(isSuccess && data),
    };
  }, [data, isSuccess]);
};
