import React from "react";
import {
  useGetBooksAutocompleteQuery,
  useTriggerAmzSearchQuery,
} from "../../app/services/mastraApi";
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { reverse } from "named-urls";
import routes from "../routes";
import { Link } from "react-router-dom";
import SearchBar from "../search/Searchbar";
import { useParams } from "react-router-dom";
import AutocompleteRow from "../search/AutocompleteRow";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

/**
 * Renders a loading spinner plus an optional message
 */
const loadingPlaceholder = (msg) => (
  <Box sx={{ textAlign: "center" }}>
    <CircularProgress />
    <Typography>{msg}</Typography>
  </Box>
);

/**
 * Displays a search bar and result list.
 * On every search it updates the "query" parameter inside the URL,
 * then triggers amazon scraping from backend, waits for it to complete
 * then fetches the normal search in our database and displays results
 * to the user
 */
export default function SearchPage() {
  //TODO: must refresh page. Fix this
  const { query: queryTerm } = useParams();
  const {
    isSuccess,
    isError,
    isLoading: isAmzLoading,
  } = useTriggerAmzSearchQuery({
    q: queryTerm,
  });

  // When user submits search, queryTerm gets updated via the
  // URL parameter. We then trigger a new Amazon search, wait
  // for it to cache reponses then search on our DB

  return (
    <Container fixed>
      {/* Back button */}
      <Button
        variant="text"
        sx={{ mt: 2 }}
        startIcon={<ArrowBackIcon />}
        component={Link}
        to="/"
      >
        {"Torna alla home"}
      </Button>
      {/* Search bar */}
      <Box sx={{ my: 2 }}>
        <SearchBar defaultValue={queryTerm} showSuggestions={false}></SearchBar>
      </Box>
      {/* Results */}
      {isAmzLoading ? (
        loadingPlaceholder("Ricerca in corso...")
      ) : isSuccess || isError ? (
        // TODO: invalidate frontend cache after amazon scraping
        <SearchResults queryTerm={queryTerm + "a"} />
      ) : (
        ""
      )}
    </Container>
  );
}

/** Given a queryTerm, fetches api to get search results
 * and displays them in a list format.
 * If no results, renders a link to amazon search
 */
function SearchResults({ queryTerm }) {
  const { data, isLoading } = useGetBooksAutocompleteQuery({ q: queryTerm });

  if (isLoading) {
    return loadingPlaceholder("Ricerca MastraBooks...");
  } else if (data && data.length > 0) {
    return data.map((result) =>
      AutocompleteRow(
        {
          component: Link,
          to: reverse(routes.book.show, {
            bookId: result.id,
            seo: result.title,
          }),
        },
        result
      )
    );
  } else {
    return (
      <Box style={{ textAlign: "center" }} sx={{ my: 7 }}>
        <Typography align="center" variant="h6">
          Nessun risultato.Vuoi provare su Amazon?
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          href={`https://www.amazon.it/gp/search?tag=${process.env.REACT_APP_AMZ_TAG}&keywords=${queryTerm}`}
          target="_blank"
          rel="noreferrer"
          startIcon={<OpenInNewIcon />}
          fullWidth
          sx={{ mt: 1 }}
        >
          Cerca su Amazon
        </Button>
      </Box>
    );
  }
}
