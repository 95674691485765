import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import ReactGA from "react-ga4";
import {
  useLazyGetTestErrorQuery,
  useLazyGetTestCrashQuery,
} from "../../app/services/mastraApi";
import { useCreateListingMutation } from "../../app/services/mastraApi";

export default function TestPage() {
  const [err, setErr] = useState(false);
  const [triggerErr] = useLazyGetTestErrorQuery();
  const [triggerCrash] = useLazyGetTestCrashQuery();
  const [createListing] = useCreateListingMutation();

  useEffect(() => {
    if (err) throw Error("Kaboom");
  }, [err]);

  return (
    <Container>
      <p>
        REACT_APP_GA_MEASUREMENT_ID: {process.env.REACT_APP_GA_MEASUREMENT_ID}
      </p>
      <p>REACT_APP_GA_ENABLE: {process.env.REACT_APP_GA_ENABLE}</p>
      <p>REACT_APP_HOTJAR_ENABLE: {process.env.REACT_APP_HOTJAR_ENABLE}</p>
      <p>REACT_APP_HOTJAR_SITE_ID: {process.env.REACT_APP_HOTJAR_SITE_ID}</p>
      <p>NODE_ENV: {process.env.NODE_ENV}</p>
      <p>REACT_APP_AMZ_TAG: {process.env.REACT_APP_AMZ_TAG}</p>
      <Button onClick={() => setErr(true)}>Kaboom</Button>
      <Button
        onClick={() =>
          ReactGA.event("unlock_achievement", "You discovered test page!")
        }
      >
        Test GA
      </Button>

      <Button onClick={() => triggerErr()}>Trigger error</Button>
      <Button onClick={() => triggerCrash()}>Trigger crash</Button>
      <Button onClick={() => createListing({ book: "12" })}>Bad request</Button>
    </Container>
  );
}
