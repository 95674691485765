import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isVisible: false,
  message: "",
  severity: "info",
};

export const showSuccess = createAsyncThunk(
  "snackbarMsg/showSuccess",
  async () => {
    await new Promise((resolve) => setTimeout(() => resolve(), 2000));
  }
);
export const showError = createAsyncThunk("snackbarMsg/showError", async () => {
  await new Promise((resolve) => setTimeout(() => resolve(), 4000));
});
export const showInfo = createAsyncThunk("snackbarMsg/showInfo", async () => {
  await new Promise((resolve) => setTimeout(() => resolve(), 4000));
});
export const show = createAsyncThunk("snackbarMsg/show", async () => {
  await new Promise((resolve) => setTimeout(() => resolve(), 4000));
});

export const snackbarSlice = createSlice({
  name: "snackbarMsg",
  initialState,
  reducers: {
    hide: (state, _action) => {
      state.isVisible = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(showSuccess.pending, (state, action) => {
        state.isVisible = true;
        state.message = action.meta.arg;
        state.severity = "success";
      })
      .addCase(showSuccess.fulfilled, (state) => {
        state.isVisible = false;
      })
      .addCase(showError.pending, (state, action) => {
        state.isVisible = true;
        state.message = action.meta.arg;
        state.severity = "error";
      })
      .addCase(showError.fulfilled, (state) => {
        state.isVisible = false;
      })
      .addCase(showInfo.pending, (state, action) => {
        state.isVisible = true;
        state.message = action.meta.arg;
        state.severity = "info";
      })
      .addCase(showInfo.fulfilled, (state) => {
        state.isVisible = false;
      })
      .addCase(show.pending, (state, action) => {
        state.isVisible = true;
        state.message = action.meta.arg.msg;
        state.severity = action.meta.arg.severity;
      })
      .addCase(show.fulfilled, (state) => {
        state.isVisible = false;
      });
  },
});

export const { hide } = snackbarSlice.actions;

export const selectIsVisible = (state) => state.snackbar.isVisible;
export const selectMessage = (state) => state.snackbar.message;
export const selectSeverity = (state) => state.snackbar.severity;

export default snackbarSlice.reducer;
