import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setEmail, selectEmail } from "./listingsWizardSlice";
import { showInfo } from "../snackbar/snackbarSlice";
import { useRegistrationMutation } from "../../app/services/mastraApi";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Typography } from "@mui/material";
import { useAuth } from "../auth/useAuth";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ValidatedEmailField } from "../auth/ValidatedEmailField";

export default function StepAddListingContacts({
  isActive,
  nextStep,
  previousStep,
}) {
  const [register, { isError: isRegisterErr, isSuccess: isRegisterSucc }] =
    useRegistrationMutation();
  const { isLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const email = useSelector(selectEmail);
  const history = useHistory();

  // Form validation hook
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email } });

  const onSubmit = (data) => {
    dispatch(setEmail(data.email));
    register({ ...data });
  };

  // Listen for registration events
  useEffect(() => {
    if (!isActive) {
      return;
    }
    if (isLoggedIn || isRegisterSucc) {
      nextStep();
    } else if (isRegisterErr) {
      dispatch(showInfo("Email già registrata, esegui il login"));
      history.push(
        `/login?email=${email}&redirect=${
          history.location.pathname
        }${history.location.hash.replace("#", "%23")}`
      );
    }
  }, [
    isRegisterSucc,
    isRegisterErr,
    isLoggedIn,
    isActive,
    dispatch,
    email,
    history,
    nextStep,
  ]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography
        variant="h4"
        component="h1"
        noWrap
        align="center"
        sx={{ mb: 4 }}
      >
        Ci siamo quasi!
      </Typography>

      <ValidatedEmailField
        control={control}
        errors={errors}
        label="Email dove vuoi essere contattato"
        placeholder="mario.rossi@example.com"
      />

      <Typography variant="body2" sx={{ my: 2 }}>
        Se sei già registrato, ti verrà richiesta la password. Altrimenti, ti
        invieremo le credenziali con cui potrai gestire il tuo annuncio.
      </Typography>

      <Box sx={{ textAlign: "right" }}>
        <Button onClick={previousStep} sx={{ mt: 2, mr: 1 }}>
          Indietro
        </Button>
        <Button
          variant="contained"
          type="submit"
          endIcon={<ArrowForwardIcon />}
          sx={{ mt: 2 }}
        >
          Avanti
        </Button>
      </Box>
    </Box>
  );
}
