import { React, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useParams, useHistory } from "react-router-dom";
import { Alert } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showSuccess } from "../snackbar/snackbarSlice";
import Avatar from "@mui/material/Avatar";
import { usePasswordResetConfirmMutation } from "../../app/services/mastraApi";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Copyright } from "./Copyright";

export function PasswordResetVerify() {
  const { uid, token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [resetConfirm, { isSuccess, error: reqErrs }] =
    usePasswordResetConfirmMutation();

  // Form validation hook
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors: formErrs },
  } = useForm({
    defaultValues: {
      new_password1: "",
      new_password2: "",
    },
  });

  // Handle form submission
  const onSubmit = (formData) => {
    resetConfirm({ uid, token, ...formData });
  };

  useEffect(() => {
    if (reqErrs) {
      for (const key in reqErrs.data) {
        setError(key, { type: "server", message: reqErrs.data[key] });
      }
    }
  }, [reqErrs, setError]);

  // On successful register, show a toast message
  useEffect(() => {
    if (isSuccess) {
      dispatch(showSuccess("Email aggiornata. Esegui il login!"));
      history.push("/login");
    }
  }, [isSuccess, dispatch, history]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockResetIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Crea nuova password
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <PasswordField
            name="new_password1"
            control={control}
            errors={formErrs}
          />
          <PasswordField
            name="new_password2"
            control={control}
            errors={formErrs}
            label="Ripeti password"
          />
          {reqErrs?.data?.token && (
            <Alert severity="error">
              Richiesta scaduta. Prova ad inviare una nuova email di reset.
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Aggiorna password
          </Button>
          <Button fullWidth component={Link} to="/login">
            {"Torna al login"}
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}

function PasswordField({ name, control, errors, ...other }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <TextField
          margin="normal"
          required
          fullWidth
          name={name}
          label="Password"
          type="password"
          autoComplete="new-password"
          error={errors[name]}
          helperText={errors[name]?.message}
          {...field}
          {...other}
        />
      )}
    />
  );
}
