import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import StepWizard from "react-step-wizard";
import StepAddListingInfo from "./stepAddListingInfo";
import StepListingContacts from "./stepListingContacts";
import StepCreateListing from "./stepCreateListing";
import StepCompleted from "./StepCompleted";
import Container from "@mui/material/Container";

export default function ListingsWizard() {
  const { bookId } = useParams();
  const [listing, setListing] = useState();

  /* Inputs states are stored in redux for persistence
  in case the user gets redirected to the login page */

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 10 }}>
        <StepWizard isHashEnabled={true}>
          <StepAddListingInfo />
          <StepListingContacts />
          <StepCreateListing bookId={bookId} onListingCreated={setListing} />
          <StepCompleted bookId={bookId} listing={listing} />
        </StepWizard>
      </Box>
    </Container>
  );
}
