import { React, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useLoginMutation } from "../../app/services/mastraApi";
import { Link } from "react-router-dom";
import { useUrlQuery } from "../useUrlQuery";
import { Alert } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showSuccess } from "../snackbar/snackbarSlice";
import routes from "../routes";
import { Copyright } from "./Copyright";
import { ValidatedEmailField } from "./ValidatedEmailField";

export function Login() {
  const [login, { isError, isSuccess }] = useLoginMutation();
  const dispatch = useDispatch();
  const queryParams = useUrlQuery();

  const defaultEmail = queryParams.get("email") || "";

  // Form validation hook
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: defaultEmail,
      password: "",
    },
  });

  // Handle form submission
  const onSubmit = (data) => {
    login({ ...data });
  };

  // On successful login, show a toast message
  useEffect(() => {
    if (isSuccess) {
      dispatch(showSuccess("Login completato"));
    }
  }, [isSuccess, dispatch]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <ValidatedEmailField
            control={control}
            errors={errors}
            autoFocus={defaultEmail === ""}
          />

          <Controller
            name="password"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                error={errors.password}
                autoFocus={defaultEmail !== ""}
                helperText={errors.password && "Ricontrolla la password"}
                {...field}
              />
            )}
          />

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          {isError && (
            <Alert severity="error">Ricontrolla e-mail e password</Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log In
          </Button>

          <Button fullWidth component={Link} to={routes.passwordReset.send}>
            {"Password dimenticata?"}
          </Button>
          <Button fullWidth component={Link} to="/register">
            {"Non hai un account? Registrati"}
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
