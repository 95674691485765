import React from "react";
import { useGetBookListingsQuery } from "../../app/services/mastraApi";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { CardActionArea } from "@mui/material";
import normalizePrice from "../normalizePrice";
import routes from "../routes";
import { reverse } from "named-urls";

export const AVAIL_CONDITIONS = [
  { id: "NEW", desc: "Come nuovo" },
  { id: "USED", desc: "Usato senza sottolineature" },
  { id: "USED_PENCIL", desc: "Sottolineato a matita" },
  { id: "USED_PEN", desc: "Sottolineato a penna" },
  { id: "USED_HIGHLIGHTER", desc: "Evidenziato" },
  { id: "USED_GOOD", desc: "Buono stato" },
  { id: "USED_DEFECT_MIN", desc: "Con difetti minori" },
  { id: "USED_DEFECT_MAX", desc: "Con difetti evidenti" },
];

export default function Listings(props) {
  const {
    data: listings,
    isError,
    isLoading,
  } = useGetBookListingsQuery({ bookId: props.bookId, uniId: props.uniId });
  return (
    <div>
      {isError
        ? "Errore"
        : isLoading
        ? "Loading...."
        : listings.length === 0
        ? "Nessun annuncio disponibile"
        : listings.map((l, key) => (
            <ListingCard key={key} listing={l} sx={{ mb: 0.7 }} />
          ))}
    </div>
  );
}

function ListingCard({ listing, icon, ...other }) {
  // Set default icon if not provided
  icon =
    icon === undefined ? (
      <ArrowForwardIos className="listing-action" color="primary" />
    ) : (
      icon
    );
  /* <AddCircle className="listing-action" />; */

  return (
    <Card
      //TODO: apply as material theme
      className="foreground-card"
      elevation={0}
      style={{ borderRadius: "16px" }}
      {...other}
    >
      <CardActionArea
        component={Link}
        to={reverse(routes.listing.show, { id: listing.id })}
      >
        <CardContent style={{ padding: "21px 30px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              columnGap: 1,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography>
                {
                  AVAIL_CONDITIONS.filter((c) => c.id === listing.condition)[0]
                    .desc
                }
              </Typography>
              {/* TODO: apply as material theme*/}
              <Typography variant="caption" style={{ color: "#6c757d" }}>
                {listing.university.university_name}
              </Typography>
            </Box>
            <Box>
              <Typography noWrap>{normalizePrice(listing.price)}</Typography>
            </Box>
            <Box>{icon}</Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
