import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

/***
 * This is a generic MUI Button wrapper that allows to
 * specify a fake delay before the onClick event is fired
 * This is useful to slow down actions to provide a user feedback
 * and sense of loading.
 *
 * fakeDelayMs: number of milliseconds to wait before firing onClick
 * onClick: function to call when fake loading is done
 * loading: boolean to indicate real loading state after fake loading
 *
 * you can also pass every other MUI Button props
 */
export default function Btn({
  fakeDelayMs = 600,
  loading: isRealLoading,
  onClick,
  children,
  askConfirm = false,
  confirmTitle = "Conferma azione",
  confirmMsg = "Sei sicuro di voler eliminare l'elemento?",
  ...other
}) {
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleBtnClick = () => {
    if (askConfirm) {
      setOpen(true);
    } else {
      handleConfirm();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    setIsFakeLoading(true);
    setTimeout(() => {
      onClick();
      setIsFakeLoading(false);
    }, fakeDelayMs);
  };

  const handleAbort = () => {
    setOpen(false);
  };

  return (
    <>
      <LoadingButton
        loading={isRealLoading || isFakeLoading}
        {...other}
        onClick={handleBtnClick}
      >
        {children}
      </LoadingButton>
      <Dialog
        open={open}
        onClose={handleAbort}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{confirmTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAbort}>Annulla</Button>
          <Button onClick={handleConfirm} autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
