import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";

Sentry.init({
  dsn: "https://99c5049e25264845820f48a641bc5a71@o1241039.ingest.sentry.io/6394439",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || "0.0"
  ),
});

let theme = createTheme({
  palette: {
    type: "light",
    background: {
      default: "#FAFAFA",
    },
    primary: {
      main: "#0B7CFF",
      contrastText: "#FFF",
    },
    text: {
      primary: "#212121",
      secondary: "#636363",
    },
    secondary: {
      main: "#ffc107",
      contrastText: "#212529",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
