import React from "react";
import { useAuth } from "../auth/useAuth";
import {
  useServerSideLogoutMutation,
  useGetUserListingsQuery,
  useDeleteListingMutation,
  useRenewListingMutation,
} from "../../app/services/mastraApi";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { Typography, Alert } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

import Container from "@mui/material/Container";

import { useDispatch } from "react-redux";
import { showSuccess } from "../snackbar/snackbarSlice";
import { CarouselItem } from "../carousel/BookCarousel";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Btn from "../Btn";

export default function ProfilePage() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [triggerLogout, { isLoading: isLoggingOut }] =
    useServerSideLogoutMutation();

  const handleLogout = () => {
    // Must display message from here before logout happens
    // otherwise component gets unmounted by AuthRoute
    dispatch(showSuccess("Sei uscito correttamente"));
    triggerLogout();
  };

  return (
    <Container fixed>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}></Avatar>
        <Typography component="h1" variant="h5">
          {user.email}
        </Typography>
        <Btn
          variant="outlined"
          startIcon={<LogoutIcon />}
          onClick={handleLogout}
          sx={{ m: 2 }}
          loading={isLoggingOut}
        >
          Logout
        </Btn>
        <UserListings />
      </Box>
    </Container>
  );
}

function UserListings() {
  const { data: listings, isLoading } = useGetUserListingsQuery();

  return (
    <>
      <Typography component="h1" variant="h6" sx={{ mt: 3, mb: 2 }}>
        I miei annunci
      </Typography>
      {isLoading
        ? "Loading..."
        : listings.length === 0
        ? "Nessun annuncio pubblicato"
        : listings.map((l, key) => (
            <ProfileListingCard listing={l} key={key} />
          ))}
    </>
  );
}

function ProfileListingCard({ listing }) {
  const [deleteListing, { isLoading: isDeleting }] = useDeleteListingMutation();
  const [renewListing, { isLoading: isRenewing }] = useRenewListingMutation();

  return (
    <Box sx={{ mb: 5 }}>
      <CarouselItem book={listing.book} />
      <br />
      {listing.is_expired && (
        <>
          <Alert severity="warning" sx={{ mb: 1 }}>
            Annuncio scaduto
          </Alert>
          <Btn
            color="secondary"
            variant="contained"
            // onClick={() => alert(listing.id)}
            onClick={() => renewListing(listing.id)}
            fullWidth
            sx={{ mb: 1 }}
            startIcon={<UpdateIcon />}
            fakeDelayMs={1100}
            loading={isRenewing}
          >
            Rinnova
          </Btn>
        </>
      )}
      <Btn
        color="error"
        variant="contained"
        onClick={() => deleteListing(listing.id)}
        askConfirm
        confirmMsg={`Sei sicuro di voler eliminare l'annuncio per il libro "${listing.book.title}"?`}
        fullWidth
        startIcon={<DeleteForeverIcon />}
        fakeDelayMs={1100}
        loading={isDeleting}
      >
        Elimina
      </Btn>
    </Box>
  );
}
