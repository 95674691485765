import React from "react";
import { Divider, Button, Typography, Stack } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useGetBookByIdQuery } from "../../app/services/mastraApi";
import { reverse } from "named-urls";
import routes from "../routes";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import BookIcon from "@mui/icons-material/Book";

export default function StepCompleted({ bookId, listing }) {
  const { data: book } = useGetBookByIdQuery(bookId);

  return listing ? (
    <Stack direction="column" spacing={2} sx={{ textAlign: "center" }}>
      <Typography variant="h5" component="h1">
        <b>Annuncio pubblicato! </b>
      </Typography>
      <Typography variant="body1" component="p" sx={{ py: 3 }}>
        Lo sapevi che condividere il tuo annuncio aumenta del <b>74%</b> le
        probabilità di vendita? Che aspetti? Condividilo subito!
      </Typography>
      <Button
        startIcon={<WhatsAppIcon />}
        aria-label="share"
        component="a"
        target="_blank"
        href={`whatsapp://send?text=Ho appena messo in vendita un libro su Mastrabooks%0a%0a📚 Titolo: ${
          book?.title || "Titolo sconosciuto"
        }%0a👤 Autore: ${
          book?.authors.join(", ") || "Autore sconosciuto"
        }%0a%0aPuoi vedere l'annuncio qui https://mastrabooks.com${reverse(
          routes.listing.show,
          { id: listing.id }
        )}
        `}
        data-action="share/whatsapp/share"
        variant="contained"
        color="success"
        size="large"
      >
        Condividi su WhatsApp
      </Button>
      <Button
        startIcon={<TelegramIcon />}
        aria-label="share"
        component="a"
        target="_blank"
        href={`tg://msg_url?text=Ho appena messo in vendita un libro su Mastrabooks%0a%0a📚 Titolo: ${
          book?.title || "Titolo sconosciuto"
        }%0a👤 Autore: ${
          book?.authors.join(", ") || "Autore sconosciuto"
        }%0a%0aPuoi vedere l'annuncio qui https://mastrabooks.com${reverse(
          routes.listing.show,
          { id: listing.id }
        )}
        `}
        variant="contained"
        size="large"
      >
        Condividi su Telegram
      </Button>
      <Divider sx={{ py: 3 }}>
        <Typography variant="body1" color="text.secondary">
          oppure
        </Typography>
      </Divider>
      <Stack direction="row" spacing={2}>
        <Button
          fullWidth
          startIcon={<BookIcon />}
          href="../"
          variant="link"
          size="large"
        >
          Pagina del libro
        </Button>
        <Button
          fullWidth
          startIcon={<NoteAddOutlinedIcon />}
          href="https://mastrabooks.com"
          variant="link"
          size="large"
        >
          Vendi altri libri
        </Button>
      </Stack>
    </Stack>
  ) : (
    "Errore creazione annuncio"
  );
}
