import * as React from "react";
import { useGetBookByIdQuery } from "../../app/services/mastraApi";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import routes from "../routes";
import { reverse } from "named-urls";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Person from "@mui/icons-material/Person";
import Business from "@mui/icons-material/Business";
import Bookmark from "@mui/icons-material/Bookmark";
import Button from "@mui/material/Button";
import TextAlongIcon from "../../textAlongIcon";

export default function BookTile({ bookId }) {
  const { data: b, error, isLoading } = useGetBookByIdQuery(bookId);

  const bookInfo = () => (
    <>
      <Typography variant="h5" sx={{ my: 1.5 }}>
        {b.title}
      </Typography>

      <TextAlongIcon
        dense
        icon={<Person />}
        secondary={`di ${b.authors.join(", ")}`}
      />
      <TextAlongIcon dense icon={<Business />} secondary={b.publisher} />
      <TextAlongIcon dense icon={<Bookmark />} secondary={b.isbn} />
    </>
  );

  const buttons = () => (
    <>
      <Button
        variant="contained"
        color="secondary"
        href={b.amz_ref}
        target="_blank"
        rel="noreferrer"
        fullWidth
      >
        Visualizza su Amazon
      </Button>
      <Button
        to={reverse(routes.book.listingWizard, {
          bookId: b.id,
          seo: b.title,
        })}
        component={Link}
        variant="contained"
        fullWidth
        sx={{ mt: 1 }}
      >
        Vendi il tuo usato
      </Button>
    </>
  );

  return (
    <>
      {error ? (
        <>Oh no, there was an error</>
      ) : isLoading ? (
        <>Loading...</>
      ) : b ? (
        <>
          <Card
            //TODO: apply as material theme
            className="foreground-card"
            style={{ borderRadius: "16px" }}
          >
            <CardContent>
              <img
                src={b.cover_lg}
                alt="book cover"
                style={{
                  maxHeight: 300,
                  minWidth: 220,
                  objectFit: "contain",
                  margin: "0 auto",
                  display: "table",
                }}
              />
              {bookInfo()}
              {buttons()}
            </CardContent>
          </Card>
        </>
      ) : null}
    </>
  );
}
