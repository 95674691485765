import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useLocation } from "react-router-dom";

export default function FixedBottomNav() {
  const location = useLocation();

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation showLabels value={location.pathname}>
        <BottomNavigationAction
          component={Link}
          to="/"
          value="/"
          label="Home"
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          component={Link}
          to="/profile"
          value="/profile"
          label="Profilo"
          icon={<PersonIcon />}
        />
        {/*
          <BottomNavigationAction
          component={Link}
          to="/chat"
          value="/chat"
          label="Messaggi"
          icon={<ChatIcon />}
        />
        */}
      </BottomNavigation>
    </Paper>
  );
}
