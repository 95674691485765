/**
  If a frontend url is needed more than once, put here its generator
 */

/**
  Return book main page URL
  schema contains book title for SEO
  e.g. /book/bookTitle-bookId
  react router only considers the id inside the URL
*/
const bookPath = (id, title) => {
  return `/book/${title.replace(" ", "-")}-${id}`;
};

export { bookPath };
