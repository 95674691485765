import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import { CardHeader } from "@mui/material";
import { useGetListingByIdQuery } from "../../app/services/mastraApi";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import normalizePrice from "../normalizePrice";
import { AVAIL_CONDITIONS } from "../marketplace/Listings";
import TextAlongIcon from "../../textAlongIcon";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import NotesIcon from "@mui/icons-material/Notes";
import EmailIcon from "@mui/icons-material/Email";
import EuroIcon from "@mui/icons-material/Euro";
import Grid from "@mui/material/Grid";
import React from "react";
import BookTile from "../library/BookTile";
import Stack from "@mui/material/Stack";
// import { useUrlQuery } from "../useUrlQuery";
import routes from "../routes";
import { reverse } from "named-urls";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export default function ListingPage() {
  // Get listing id from url
  const { id } = useParams();

  // Fetch listing info
  const { data: listing, isLoading, isError } = useGetListingByIdQuery(id);

  return isLoading ? (
    "Loading..."
  ) : isError ? (
    "error"
  ) : (
    <Container maxWidth="sm" fixed sx={{ mt: 10 }}>
      <Stack spacing={2}>
        <Button
          fullWidth
          component={Link}
          to={reverse(routes.book.show, {
            bookId: listing.book.id,
            seo: listing.book.title,
          })}
          startIcon={<ArrowBackIcon />}
        >
          Vedi altri gli annunci per questo libro
        </Button>
        <BookTile bookId={listing.book.id} />
        <ListingCard listing={listing}></ListingCard>
      </Stack>
    </Container>
  );
}

function ListingCard({ listing }) {
  const mailtoText =
    "mailto:" +
    listing.owner.email +
    "?subject=Annuncio libro MastraBooks" +
    "&body=Ciao, ti contatto per l'annuncio del libro '" +
    listing.book.title +
    "' su MastraBooks. È ancora disponibile?";

  return (
    <Card
      fullwidth
      //TODO: apply as material theme
      className="foreground-card"
    >
      <CardHeader title="Dettagli annuncio usato" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <TextAlongIcon
              icon={<ImportContactsIcon />}
              primary={
                AVAIL_CONDITIONS.filter((c) => c.id === listing.condition)[0]
                  .desc
              }
            />
            <TextAlongIcon
              icon={<LocationOnIcon />}
              primary={listing.university.university_name}
            />
            <TextAlongIcon
              icon={<NotesIcon />}
              primary={listing.notes || "Nessuna nota"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextAlongIcon
              icon={<EuroIcon />}
              primary={normalizePrice(listing.price)}
            />
            <TextAlongIcon
              icon={<AlternateEmailIcon />}
              primary={listing.owner.email}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          startIcon={<EmailIcon />}
          component="a"
          target="_blank"
          rel="noreferrer"
          href={mailtoText}
        >
          Contatta via email
        </Button>
      </CardActions>
    </Card>
  );
}
