import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useGetUniversitiesQuery } from "../../app/services/mastraApi";
import { Controller } from "react-hook-form";

function UncontrolledAutocomplete({
  options,
  getOptionLabel,
  onChange,
  ...otherTextFieldProps
}) {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={(_, value) => {
        let newOptionId = value && value.id ? value.id : "";
        onChange(newOptionId);
      }}
      renderInput={(params) => (
        <TextField {...params} {...otherTextFieldProps} />
      )}
    />
  );
}

function ControlledAutocomplete({ control, name, ...other }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <UncontrolledAutocomplete {...other} onChange={field.onChange} />
      )}
    />
  );
}

function UniSelectAutocomplete({ control, ...other }) {
  const { data: universities } = useGetUniversitiesQuery();

  return control ? (
    // Input controlled with react-hook-form
    <ControlledAutocomplete
      control={control}
      name="university"
      label="Università"
      placeholder="Digita per cercare un'università"
      options={universities || []}
      getOptionLabel={(option) => option.university_name}
      {...other}
    />
  ) : (
    // Uncontrolled input
    <UncontrolledAutocomplete
      name="university"
      label="Università"
      placeholder="Digita per cercare un'università"
      options={universities || []}
      getOptionLabel={(option) => option.university_name}
      {...other}
    />
  );
}

export default UniSelectAutocomplete;
