import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useEffect } from "react";
import { usePasswordResetMutation } from "../../app/services/mastraApi";
import { useDispatch } from "react-redux";
import { showSuccess } from "../snackbar/snackbarSlice";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Copyright } from "./Copyright";
import { ValidatedEmailField } from "./ValidatedEmailField";

export function PasswordReset() {
  const dispatch = useDispatch();
  const [resetPass, { isSuccess }] = usePasswordResetMutation();

  // Form validation hook
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: "" },
  });

  // Handle form submission
  const onSubmit = (data) => {
    resetPass({ ...data });
  };

  // On successful register, show a toast message
  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showSuccess(
          "Ti abbiamo inviato un e-mail di recupero. Dacci un'occhiata!"
        )
      );
    }
  }, [isSuccess, dispatch]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockResetIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
          <ValidatedEmailField control={control} errors={errors} />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Inviami e-mail di reset
          </Button>

          <Button fullWidth component={Link} to="/login">
            {"Sei già registrato? Entra"}
          </Button>
          <Button fullWidth component={Link} to="/register">
            {"Non hai un account? Registrati"}
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
