import { include } from "named-urls";

const routes = {
  book: include("/book/:seo-:bookId/", {
    show: "",
    listingWizard: "listingWizard/",
  }),
  search: "/search/:query/",
  listing: include("/listing/:id/", {
    show: "",
  }),
  passwordReset: include("/password-reset/", {
    send: "",
    confirm: "confirm/:uid/:token/",
  }),
};

export default routes;
