import ProfilePage from "./features/pages/ProfilePage";
import BookPage from "./features/pages/BookPage";
import ListingPage from "./features/pages/ListingPage";

import "./css/App.css";
import React from "react";
import { Snackbar } from "./features/snackbar/Snackbar";
import { Route } from "react-router-dom";
import { Login } from "./features/auth/Login";
import { Register } from "./features/auth/Register";
import Home from "./features/pages/Home";
import FixedBottomNav from "./features/navigation/FixedBottomNav";
import FixedAppBar from "./features/navigation/FixedAppBar";
import { AuthRoute } from "./features/auth/AuthRoute";
import ListingsWizard from "./features/ListingsWizard";
import { Box } from "@mui/material";
import routes from "./features/routes";
import { PasswordReset } from "./features/auth/PasswordReset";
import { PasswordResetVerify } from "./features/auth/PasswordResetVerify";
import SearchPage from "./features/pages/SearchPage";
import TestPage from "./features/pages/TestPage";
import usePageTracking from "./features/usePageTracking";

function App() {
  usePageTracking();

  return (
    <div className="app">
      <FixedAppBar />
      <div className="app-body">
        <AuthRoute exact path="/login" publicComponent={<Login />} />
        <AuthRoute exact path="/register" publicComponent={<Register />} />
        <AuthRoute
          exact
          path={routes.passwordReset.send}
          publicComponent={<PasswordReset />}
        />
        <AuthRoute
          exact
          path={routes.passwordReset.confirm}
          publicComponent={<PasswordResetVerify />}
        />
        <AuthRoute path="/profile" privateComponent={<ProfilePage />} />
        <Route exact path="/" component={Home}></Route>
        <Route
          path="/book/(.*)-:bookId/listingWizard"
          component={ListingsWizard}
        ></Route>
        <Route exact path="/book/(.*)-:bookId" component={BookPage}></Route>
        <Route path={routes.listing.show} component={ListingPage}></Route>
        <Route path={routes.search} component={SearchPage}></Route>
        <Route path="/test" component={TestPage}></Route>
      </div>
      <Box sx={{ height: 100 }}></Box>
      <Snackbar />
      <FixedBottomNav />
    </div>
  );
}

export default App;
