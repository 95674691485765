import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  hide,
  selectIsVisible,
  selectMessage,
  selectSeverity,
} from "./snackbarSlice";
import { default as MuiSnackbar } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

export function Snackbar() {
  const isVisible = useSelector(selectIsVisible);
  const message = useSelector(selectMessage);
  const severity = useSelector(selectSeverity);

  const dispatch = useDispatch();

  return (
    <MuiSnackbar open={isVisible} TransitionComponent={Slide}>
      <Alert
        onClose={() => dispatch(hide())}
        severity={severity}
        sx={{ width: "100%" }}
        variant="filled"
        elevation={6}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
